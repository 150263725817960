import React from 'react';
import { StaticContext } from 'react-router';

interface IProps {
    staticContext: StaticContext;
}

const NotFound: React.FC<IProps> = ({ staticContext = {} }) => {
    staticContext.statusCode = 404;

    return null;
};

export default NotFound;
